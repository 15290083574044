var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('ValidationProvider',{attrs:{"name":_vm.label,"rules":_vm.rules},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',_vm._g(_vm._b({directives:[{name:"mask",rawName:"v-mask",value:('##/##/####'),expression:"'##/##/####'"}],attrs:{"label":_vm.label,"placeholder":_vm.placeholder,"disabled":_vm.disabled,"outlined":_vm.outlined,"readonly":_vm.readonly,"hide-details":_vm.hideDetails,"dense":_vm.dense,"error-messages":errors,"showAppendOuter":_vm.showAppendOuter,"nameIcon":_vm.nameIcon,"textTooltip":_vm.textTooltip},on:{"blur":function($event){_vm.date = _vm.parseDate(_vm.dateFormatted)}},scopedSlots:_vm._u([(_vm.showAppendOuter)?{key:"append-outer",fn:function(){return [_c('v-tooltip',{staticClass:"pb-7",attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pb-7 pl-1 pr-2 outlined_v_icon",attrs:{"slot":"activator","color":"#96999A"},slot:"activator"},on),[_vm._v(_vm._s(_vm.nameIcon))])]}}],null,true)},[_c('span',{staticClass:"pb-7 textTooltip",domProps:{"innerHTML":_vm._s(_vm.textTooltip)}})])]},proxy:true}:null],null,true),model:{value:(_vm.dateFormatted),callback:function ($$v) {_vm.dateFormatted=$$v},expression:"dateFormatted"}},'v-text-field',attrs,false),on))]}}],null,true)})]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"locale":"es-MX","allowed-dates":_vm.allowedDates,"min":_vm.min,"max":_vm.max,"color":_vm.colorDias,"header-color":_vm.colorHeader},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }